import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Hero from "../components/hero";
import backdrop from "../images/header-image-congress.png";
import CongressCard from "../components/congressCard";

const Congress = () => {
	const [sortByDate, setSortByDate] = useState(true);
	const [upcoming, setUpcoming] = useState(undefined);
	const [past, setPast] = useState(undefined);

	const isUpcoming = eventDate => {
		var today = Date.now();
		var congress = new Date(eventDate);

		return today <= congress;
	};

	const setNextUpcoming = events => {
		const next = events.sort(
			(a, b) => b.event_end_date__c - a.event_end_date__c
		)[0];
		setUpcoming(next);
	};

	const formatEventDate = cEvent => {
		if (!cEvent.event_end_date__c || !cEvent.event_start_date__c) {
			return "";
		}

		var start = new Date(cEvent.event_start_date__c);
		var end = new Date(cEvent.event_end_date__c);

		var dateFormat = start.toLocaleString(undefined, {
			timeZone: "UTC",
			month: "short",
			day: "numeric",
		});

		if (
			start.getMonth() === end.getMonth() &&
			start.getDay() === end.getDay()
		) {
			dateFormat +=
				", " +
				start.toLocaleString(undefined, { timeZone: "UTC", year: "numeric" });
		} else if (start.getMonth() !== end.getMonth()) {
			dateFormat +=
				" - " +
				end.toLocaleString(undefined, {
					timeZone: "UTC",
					month: "short",
					day: "numeric",
				});
		} else {
			dateFormat +=
				" - " +
				end.toLocaleString(undefined, { timeZone: "UTC", day: "numeric" });
			dateFormat +=
				", " +
				start.toLocaleString(undefined, { timeZone: "UTC", year: "numeric" });
		}

		return dateFormat;
	};

	useEffect(() => {
		async function fetchData() {
			try {
				//console.log("Trying to re-sort");
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/congress-events"
				);
				if (!result.data.errors) {
					const prior = [];
					const next = [];

					result.data.forEach(c => {
						if (c.home_only__c) {
							return;
						}
						if (isUpcoming(c.event_end_date__c)) {
							next.push(c);
						} else {
							prior.push(c);
						}
					});
					setNextUpcoming(next);
					if (sortByDate) {
						var byDate = prior.sort((a, b) => b.event_end_date__c - a.event_end_date__c);
						console.log(byDate);
						setPast(
							byDate
						);
					} else {
						console.log("Prior: " + prior);
						var byAlpha = prior.sort(function (a, b) {
							if (a.event_title__c < b.event_title__c) { return -1; }
							if (a.event_title__c > b.event_title__c) { return 1; }
							return 0;
						})
						console.log(byAlpha);
						setPast(
							byAlpha
						);
					}
				}
			} catch (e) {
				console.log(e);
			}
		}

		fetchData();
	}, [sortByDate]);

	return (
		<Layout contact>
			<SEO title="Congress" />
			<Hero background={backdrop} large>
				<div className="col">
					<h1 className="hero-congress-heading">Akebia at Congress</h1>
					<p className="hero-congress-body">
						Stay up to date on the latest meetings and events happening across
						the nation and internationally.
					</p>
					{upcoming && (
						<CongressCard
							congress={{
								name: upcoming.event_title__c,
								date: formatEventDate(upcoming),
								body: upcoming.event_description__c,
								button: upcoming.event_button_text__c,
								link: upcoming.event_button_link__c,
								featured: true,
							}}
						/>
					)}
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/congress"
									className="active"
								>
									Congress
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="section">
				<div className="container">
					<div className="row">
						<div className="col">
							<h2 className="congress-cards-heading">Past Congresses</h2>
							<button
								onClick={e => setSortByDate(true)}
								className={
									sortByDate ? "btn btn-bordered active" : "btn btn-bordered"
								}
							>
								Sort By Date
							</button>
							<button
								onClick={e => setSortByDate(false)}
								className={
									!sortByDate ? "btn btn-bordered active" : "btn btn-bordered"
								}
							>
								Sort By Organization
							</button>
							<div className="congress-cards-wrapper">
								{past &&
									past.map(pastEvent => {
										return (
											<CongressCard
												key={pastEvent.id}
												congress={{
													name: pastEvent.event_title__c,
													date: formatEventDate(pastEvent),
													body: pastEvent.event_description__c,
													button: pastEvent.event_button_text__c,
													link: pastEvent.event_button_link__c,
												}}
											/>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Congress;
